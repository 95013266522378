.radio-group {
  display: flex !important;
  font-family: "ProximaNova-Semibold";
  font-weight: 400;

  &__item {
    border-color: #0077e2 !important;
    width: 50%;

    &.uppercase.ant-radio-button-wrapper {
      text-transform: uppercase;
    }

    &.ant-radio-button-wrapper {
      color: #0077e2;

      &-disabled {
        color: #ccc;
        background: none;

        &::before {
          display: none;
        }

        &:hover {
          background: none;
        }
      }

      &.ant-radio-button-wrapper-checked {
        background: #0077e2 !important;

        &:hover {
          background: #0077e2 !important;
        }

        &:focus-within {
          box-shadow: none !important;
        }
      }

      &.ant-radio-button-wrapper:focus-within {
        box-shadow: none !important;
      }

      & > span:last-child {
        user-select: none;
      }
    }
  }
}
