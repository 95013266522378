.custom-button.ant-btn {
  text-transform: uppercase;

  &.ant-btn-primary {
    background-color: #0077e2;
  }

  &.ant-btn-default {
    color: #0077e2;
    border-color: #0077e2;
  }

  &:disabled {
    border-color: #d9d9d9;
    background-color: #f4f5f7;
    color: rgba(0, 0, 0, 0.25);
  }
}
