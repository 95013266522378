.task-media-gallery {
  .carousel {
    .slider {
      height: calc(100vh - 100px);
      img {
        pointer-events: initial;
      }
    }
    &.carousel-slider {
      .control-arrow {
        padding: 50px;
      }
    }
    .slide {
      .legend {
        width: 80%;
        margin-left: -40%;
      }
    }
  }
}
