:root {
  /* blue */
  --blue-0: #8fc8eb;
  --blue-1: #429ebd;
  --blue-2: #4675c0;
  --blue-3: #cfd9df;
  --blue-4: #697a98;
  --blue-5: #6ba7ff;
  --blue-6: #053f5c;
  --blue-7: #6a8c9d;
  --blue-8: #578ce1;
  --blue-9: #19335a;

  /* gray */
  --gray-0: #e5eaed;
  --gray-1: #e8ebee;
  --gray-2: #dee1e4;
  --gray-3: #cfd9df;
  --gray-4: #9b9b9b;
  --gray-5: #d7d7d7;
  --gray-6: #f7f7f7;

  /* orange */
  --orange-5: #f27f0c;

  /* red */
  --red-0: #fff3f5;
  --red-5: #d0021b;

  /* green */
  --green-1: #6dd400;
  --green-5: #55a600;
  --green-6: #1c7b3c;

  /* yellow */
  --yellow-0: #fffbee;
  --yellow-5: #ffc700;

  /* white */
  --white: #ffffff;

  /* border */
  --common-border: #cfd9df;

  /* gradient */
  --gradient-menu-background: linear-gradient(180deg, var(--blue-6) 0%, var(--blue-9) 100%);
  --gradient-card-background: linear-gradient(180deg, var(--white) 0%, var(--gray-1) 100%);
  --gradient-new: linear-gradient(180deg, var(--white) 1.98%, var(--yellow-0) 100%);
  --gradien-backdrop: linear-gradient(180deg, rgba(5, 63, 92, 0.6) 0%, rgba(25, 51, 90, 0.6) 100%);

  /* shadow */
  --shadow-card: 0px 2px 2px rgba(159, 198, 209, 0.5), 0px 2px 4px rgba(159, 198, 209, 0.5), 1px 2px 8px #9fc6d1;
}

/* Accent colors */
$accent-color: #0077e2;
$accent-color-hover: #00a3ff;
$accent-color-pressed: #0054a0;
$accent-color-disabled: #b2d6f6;
/* End of Accent colors */

/* Ghost colors */
$ghost-color: #a3b3bd;
$ghost-color-hover: #bcceda;
$ghost-color-pressed: #8e9da7;
$ghost-color-disabled: #e3e8eb;
/* End of Ghost colors */

/* Error colors */
$error-color: #d32f2f;
$error-color-hover: #f93a3a;
$error-color-pressed: #a82424;
$error-color-disabled: #f2c1c1;
/* End of Error colors */

/* Warning colors */
$warning-color: #ed6c02;
$warning-color-hover: #ff8a00;
$warning-color-pressed: #d05e00;
$warning-color-disabled: #fad3b3;
/* End of Warinng colors */

/* Success colors */
$success-color: #2e7d32;
$success-color-hover: #3ea943;
$success-color-pressed: #246427;
$success-color-disabled: #c0d8c1;
/* End of Success colors */

/* Info colors */
$info-color: #0288d1;
$info-color-hover: #00a5ff;
$info-color-pressed: #006fac;
$info-color-disabled: #b3dbf1;
/* End of Info colors */

$text-color: #1c2433;
$btn-primary-text-color: #ffffff;
