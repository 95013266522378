.task-badge {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #fa9c00;

  &.planned {
    background-color: #7ed321;
  }
}
