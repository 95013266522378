.task-signature-journal {
  &__content {
    & .ant-card-body {
      display: flex;
      flex-direction: column;
      padding: 32px;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
