$indent: 90px;

.coments {
  .ant-timeline.ant-timeline-label {
    .ant-timeline-item-label {
      width: calc($indent - 12px);
    }
    .ant-timeline-item-left .ant-timeline-item-content {
      width: calc(100% - $indent - 12px);
      inset-inline-start: calc($indent - 4px);
    }
    .ant-timeline-item-head {
      inset-inline-start: $indent;
    }
    .ant-timeline-item-tail {
      inset-inline-start: $indent;
    }
  }
}
