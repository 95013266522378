.ant-typography {
  &.h1 {
    font-size: 96px;
  }

  &.h2 {
    font-size: 72px;
  }

  &.h3 {
    font-size: 48px;
  }

  &.h4 {
    font-size: 36px;
  }

  &.h5 {
    font-size: 32px;
  }

  &.h6 {
    font-size: 28px;
  }

  &.body1 {
    font-size: 24px;
  }

  &.body2 {
    font-size: 20px;
  }

  &.body3 {
    font-size: 18px;
  }

  &.label1 {
    font-size: 16px;
  }

  &.label2 {
    font-size: 14px;
  }

  &.label3 {
    font-size: 12px;
  }

  &.label4 {
    font-size: 10px;
  }

  &.thin {
    font-weight: 250;
  }

  &.light {
    font-weight: 300;
  }

  &.regular {
    font-weight: 400;
  }

  &.semibold {
    font-family: "ProximaNova-Semibold";
  }

  &.bold {
    font-weight: 700;
  }

  &.extrabold {
    font-weight: 800;
  }

  &.black {
    font-family: "ProximaNova-Black";
  }

  &.center {
    text-align: center;
  }

  &.justify {
    text-align: justify;
  }

  &.right {
    text-align: right;
  }

  &.left {
    text-align: left;
  }

  &.start {
    text-align: start;
  }

  &.end {
    text-align: end;
  }

  &.semi-soft {
    opacity: 0.7;
  }

  &.soft {
    opacity: 0.5;
  }

  &.semi-hard {
    opacity: 0.85;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.black {
    color: black;
  }

  &.gray1 {
    color: #4a4a4a;
  }

  &.gray2 {
    color: #88939f;
  }

  &.gray3 {
    color: #bac5d1;
  }

  &.blue {
    color: #0091ff;
  }

  &.red {
    color: #FF470D;
  }

  &.white {
    color: #FFF;
  }
}
