@import "uikit/themes/default/colors.scss";
@import "uikit/themes/fonts/fonts.scss";
@import "uikit/themes/default/variables.scss";

body {
  margin: 0;
  background: #e2ebf0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  min-height: 100% !important;
  height: 100%;
  width: 100%;

  img {
    vertical-align: initial;
  }

  .ant-btn {
    img {
      vertical-align: middle;
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  min-height: 100%;
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

a {
  text-transform: none;
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.content-wrapper {
  padding: 0 30px;
}

.overflow-y {
  overflow-y: auto;
}

.base-card-background {
  background: var(--gradient-card-background);
}

.pointer {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.content {
  max-width: 1400px;
  margin: auto;
  width: 100%;
  &.wide {
    max-width: 1736px;
  }

  &.fixed-min-w {
    min-width: 1220px;
  }
}

.ant-typography {
  color: #354a5f;
}

.ant-card-body {
  padding: $spacing * 2;
}

.ellipsis-more {
  color: #0091ff;

  &:hover {
    opacity: 0.8;
  }

  cursor: pointer;
}

.ant-card,
.ant-popover-inner {
  border-radius: 8px;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ant-popover-inner-content {
  padding: 20px;
}

.page-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 16px;
}

img {
  &.ic-m {
    width: 24px;
    height: 24px;
  }

  &.ic-s {
    width: 18px;
    height: 18px;
  }

  &.ic-xs {
    width: 16px;
    height: 16px;
  }

  &.ic-l {
    width: 36px;
    height: 36px;
  }
}

.noMargin {
  margin: 0;
}

@for $i from 1 through 50 {
  .pt-#{$i} {
    padding-top: $spacing * $i;
  }

  .pb-#{$i} {
    padding-bottom: $spacing * $i;
  }

  .pl-#{$i} {
    padding-left: $spacing * $i;
  }

  .pr-#{$i} {
    padding-right: $spacing * $i;
  }

  .mt-#{$i} {
    margin-top: $spacing * $i;
  }

  .mb-#{$i} {
    margin-bottom: $spacing * $i;
  }

  .ml-#{$i} {
    margin-left: $spacing * $i;
  }

  .mr-#{$i} {
    margin-right: $spacing * $i;
  }

  .mv-#{$i} {
    margin-top: $spacing * $i;
    margin-bottom: $spacing * $i;
  }

  .mh-#{$i} {
    margin-left: $spacing * $i;
    margin-right: $spacing * $i;
  }
}

.ant-collapse {
  &.transparent {
    background-color: inherit;
  }

  &.no-borders {
    border: none;

    .ant-collapse-content {
      border: none;
    }

    .ant-collapse-item {
      border: none;
    }
  }

  &.no-spaces {
    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-top: $spacing * 2;
    }
  }
}

.ant-form {
  .ant-form-item {
    .ant-form-item-label {
      padding-bottom: 0;

      label {
        font-size: 10px;
        font-family: "ProximaNova-Semibold";
        color: #354a5f;
      }
    }
  }
}

.ant-divider {
  border-color: #d8d8d8;
}

.ant {
  &-btn {
    border-radius: 6px;

    &-circle {
      border-radius: 50%;
    }

    &-group > .ant-btn:first-child:not(:last-child) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &-group > .ant-btn:last-child:not(:first-child) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  &-modal {
    &-mask {
      z-index: 1500;
    }

    &-wrap {
      z-index: 1500;
    }

    &-body {
      padding: $spacing * 5;
    }
  }

  &-select {
    &-dropdown {
      z-index: 1700;
    }
  }

  &-picker {
    &-dropdown {
      z-index: 1700;
    }
  }

  &-form {
    &-item {
      margin-bottom: $spacing * 2;
    }
  }

  &-dropdown {
    z-index: 1700;
  }

  &-popover {
    z-index: 1700;
  }

  &-message {
    z-index: 1700;
  }

  &-radio-button {
    &-wrapper {
      text-align: center;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  &-table {
    color: #354a5f;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.lh-1 {
  line-height: 1;
}

.shift-switch {
  justify-content: center;

  .shift {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 7px;
    transition: 0.5s;
    cursor: pointer;

    &.active,
    &:hover {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
      background-color: #ffffff;
    }

    .icon {
      width: 24px;
      height: 24px;
      vertical-align: middle !important;
    }

    .title {
      vertical-align: middle;
    }
  }

  &.disabled {
    .shift {
      cursor: initial;

      &:not(.active) {
        &:hover {
          box-shadow: none;
          background-color: inherit;
        }
      }
    }
  }
}

.sidebar {
  &-open-button {
    position: fixed;
    top: 50%;
    right: -37px;
    transform: rotate(-90deg);
  }

  &-row {
    padding: 20px 0;
    border-bottom: 1px solid var(--common-border);

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    .label {
      color: var(--gray-4);
    }
  }
}

.spinner-container {
  padding: 150px 50px;
  text-align: center;
}

.blue {
  color: #0091ff;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.tabs-with-badges {
  .ant-tabs-tab-btn {
    margin-right: 24px;
  }
  &.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 4px;
  }
}
// это для всплывающих по кнопке "..." закладок файлов в карточке задачи, чтобы влезал синий кружок с количеством файлов
.ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  padding: 8px 24px 4px 12px;
}

.button-content {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.badge-RECOVERY,
.badge-SERVICE_REQUEST {
  color: var(--white);
  padding: 4px 6px;
  background: #7ed321;
  border-radius: 4px;

  &.shift-closed {
    background: #768894;
  }
}

.badge-MAINTENANCE,
.badge-ETO {
  color: var(--white);
  padding: 4px 6px;
  background: #4a90e2;
  border-radius: 4px;

  &.shift-closed {
    background: #768894;
  }
}
.badge-OPIN {
  color: var(--white);
  padding: 4px 6px;
  background: #fa9c00;
  border-radius: 4px;

  &.shift-closed {
    background: #768894;
  }
}

.badge-PREVENTIVE {
  color: var(--white);
  padding: 4px 6px;
  background: #6d7278;
  border-radius: 4px;

  &.shift-closed {
    background: #768894;
  }
}

.badge-OTHER {
  color: var(--white);
  padding: 4px 6px;
  background: #a9a9a9;
  border-radius: 4px;

  &.shift-closed {
    background: #768894;
  }
}

.page-maintenance,
.page-oossa {
  .month {
    border: solid 2px transparent;
    height: 100%;

    &.distribution {
      cursor: pointer;
      opacity: 0.5;
      padding: $spacing 0 $spacing 0;

      &.active {
        border: solid 2px #0091ff;
        border-radius: 10px;
        opacity: 1;
      }
    }
  }
}

// antd - маска компонента Drawer не перекрывала модальные окна
.ant-drawer-mask,
.ant-drawer {
  z-index: 700;
}

// antd - чтобы во всплывающих модальных окнах работали всплывающие подсказки и всплывающие меню по кнопке "..." для невмещающихся закладок
.ant-modal-mask {
  z-index: 800;
}
.ant-modal-wrap {
  z-index: 900;
}

// для кнопок, в которых может быть несколько строк текста
.ant-btn.multi-line {
  display: block;
  height: auto;
}
