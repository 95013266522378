.type-switch-field {
  font-family: "ProximaNova-Semibold";
  font-weight: 400;

  &__item {
    border-color: #0077e2 !important;
    width: 50%;

    &.ant-radio-button-wrapper {
      color: #0077e2;

      &.ant-radio-button-wrapper-checked {
        background: #0077e2 !important;

        &:hover {
          background: #0077e2 !important;
        }

        &:focus-within {
          box-shadow: none !important;
        }
      }

      &.ant-radio-button-wrapper:focus-within {
        box-shadow: none !important;
      }
    }
  }
}
