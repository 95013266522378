.form {
  max-width: 500px;
  label {
    color: white !important;
    font-size: 16px !important;
  }

  &-item {
    .ant-input,
    .ant-input-password {
      font-size: 16px;
      padding: 12px 15px;
      width: 490px;
      max-width: 100%;
      @media screen and (max-width: 490px) {
        width: 350px;
        padding: 8px 12px;
      }
    }
  }
}
