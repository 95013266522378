.attachments-field {
  margin-top: 8px;

  & &__info {
    line-height: 8px;

    &-item {
      font-family: "ProximaNova-Semibold";
      color: #bac5d1;
    }
  }

  & &__label {
    font-family: "ProximaNova-Semibold";
    font-weight: 400;
    font-size: 9px;
  }
}
