@import "uikit/themes/default/variables.scss";

.maintenance-attached-equipment-modal {
  .name {
    color: #354a5f;
  }
  .MuiDialog-paper {
    max-width: none;
    width: 730px;
  }
  .list-item {
    list-style: disc;
    list-style-position: inside;
    display: list-item;
  }
  .employee {
    color: #0091ff;
  }
  .marker {
    width: 64px;
    height: 64px;
    margin: auto;
    border-radius: 15px;
    &-wrapper {
      min-width: 150px;
      text-align: center;
      cursor: pointer;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 20px;
      height: 100%;
      border: transparent solid 1px;
      &:hover {
        border: #354a5f solid 1px;
      }
    }
    &.small {
      width: 18px;
      height: 18px;
      border-radius: 2px;
      cursor: pointer;
    }
    &.title {
      width: 32px;
      height: 32px;
      border-radius: 6px;
    }
    &.unchecked {
      border: 3px solid #a3b3bd;
    }
    &.ok {
      background-color: #6dd400;
    }
    &.repaired {
      background: #f7b500;
    }
    &.broken {
      background: #fa6400;
    }
    &.disabled {
      cursor: not-allowed;
    }
  }
  .link {
    cursor: pointer;
  }
  .optional-info {
    // height: 0;
    .showFull {
      height: auto;
    }
  }
}
