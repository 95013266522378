.page-dashboard {
  img {
    display: block;
    margin: auto;
  }
  .shifts {
    .shifts-comment {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      // elipsis с ограничением на кол-во строк доступен через webkit, иное решение более громоздкое
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    .ant {
      &-progress-line {
        line-height: 1;
        display: block;
        font-size: 0px;
      }
      &-divider-horizontal {
        margin: 16px 0;
      }
    }
    .remain {
      border-bottom: 1px solid #d8d8d8;
      align-self: end;
      &.last {
        border-bottom: none;
      }
      .header {
        .ant-typography {
          color: #6c95ba;
        }
        .ant-col:first-of-type {
          .ant-typography {
            color: #0091ff;
          }
        }
      }
      .ant-col {
        text-align: center;
      }
    }
    .common-data {
      .ant-col {
        text-align: center;
      }
      table {
        width: 100%;
      }
      tr {
        &:first-child {
          td {
            color: #6c95ba;
            &:nth-child(2) {
              color: #0091ff;
              font-family: "ProximaNova-Black";
            }
          }
        }
        &:last-child {
          td {
            &:nth-child(2) {
              color: #0091ff;
              font-family: "ProximaNova-Black";
            }
          }
        }
      }
      td {
        border-bottom: 1px solid #d8d8d8;
        padding: 8px 0;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        &:first-child {
          text-align: left;
          padding-left: 16px;
        }
      }
      tr:last-child td {
        border-bottom: none;
      }
      td:last-child {
        border-right: none;
      }
      td:nth-child(2) {
        background-color: #e5f4ff;
      }
    }
  }
  .maintenance-stats {
    table {
      width: 100%;
    }
    &.pspa {
      tr {
        &:first-child {
          td {
            &:nth-child(2) {
              color: #f79400;
              background-color: #f7b5001a;
            }
            &:nth-child(3) {
              color: #209b0c;
              background-color: #7ed32133;
            }
          }
        }
      }
    }
    td {
      border-bottom: 1px solid #d8d8d8;
      padding: 8px 0;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      &:first-child {
        text-align: left;
      }
      .advance {
        .ant-typography {
          color: #33a02c;
        }
      }
      .lag {
        .ant-typography {
          color: #ff6442;
        }
      }
    }
    tr:last-child td {
      border-bottom: none;
    }
    td:last-child {
      border-right: none;
    }
  }
  .ant-card-body {
    padding: 24px;
  }
  .navlink {
    transition: 300ms;
    &:hover {
      color: #1890ff;
    }
  }
  .ant {
    &-table {
      font-family: "ProximaNova-Semibold";
      &-thead {
        .ant-table-cell {
          background-color: #e5f4ff;
          color: #0091ff;
        }
        &.ant-table-small {
          font-size: 12px;
        }
      }
      td {
        .positive {
          color: #6dd400;
        }
        .negative {
          color: #ff0000;
        }
      }
    }
  }
}
.dashboard-byteam-table th {
  text-transform: uppercase;
}
