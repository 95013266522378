.attachment-list__item {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 144px;
  height: 70px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;

  &__button {
    position: absolute !important;
    top: -15px;
    right: -25px;
  }

  &__content {
    line-height: 14px;
  }
}
