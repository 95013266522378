.radio-field-wrapper {
  & .radio-field {
    line-height: 18px;

    &-icon {
      cursor: pointer;
    }

    &::before {
      font-size: 24px;
      content: "•";
      margin-right: 16px;
    }
  }
}

.error {
  .radio-group__item {
    border-color: #ff9b9b !important ;
  }
}
