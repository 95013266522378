.screenshot-container {
  touch-action: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 3000;
  video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-close {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
    z-index: 1010;
  }
  &-panel {
    height: 100%;
    border-radius: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 20px;
    position: absolute;
    z-index: 1010;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    &-screen {
      padding: 10px;
      border-radius: 100%;
      border: 4px white solid;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.mirrored {
  transform: scaleX(-1) translate(50%, -50%) !important;
}

.preview-images {
  width: max-content;
  max-width: 80%;
  height: auto;
  position: absolute;
  margin-bottom: 40px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
  flex-shrink: 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar:vertical {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-item {
    position: relative;
    min-width: 250px;
    max-width: 200px;
    height: 150px;
    scroll-snap-align: start;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
