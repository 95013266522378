.journal-stats {
  &__item {
    width: 80px;

    &__text {
      font-family: "ProximaNova-Regular";
      color: #354a5f;

      &--primary {
        font-size: 24px;
        font-family: "ProximaNova-Bold";
        font-weight: bold;
        line-height: 24px;
      }

      &--secondary {
        font-size: 10px;
      }
    }
  }
}
