.create-task-modal {
  & .ant-modal-body {
    display: flex;
    padding: 30px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 100%;
  }

  & .modal-button {
    font-family: "ProximaNova-Semibold";
    border-color: #0077e2;
    height: 48px;

    &.ant-btn-default {
      color: #0077e2;
    }

    &.ant-btn-primary {
      background-color: #0077e2;
    }
  }
}
