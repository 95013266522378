.error-message.ant-alert {
  font-family: "ProximaNova-Semibold";
  border-radius: 8px;
  border-color: #ff9b9b;
  padding-bottom: 8px;

  & .ant-alert-message {
    font-size: 14px;
    font-weight: bold;
    color: #d32f2f;
    margin-bottom: 0;
  }

  & .ant-alert-description {
    font-size: 12px;
  }
}
