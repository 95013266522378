.task-modal {
  .task-media-document-list {
    .ant-badge {
      color: inherit;
    }
    .task-media-document {
      border: 1px dashed #d9d9d9;
      border-radius: 12px;
      cursor: pointer;
      transition: border-color 0.3s;
      height: 100%;
      background-color: #fafafa;
      padding: 8px;
    }
  }
}
