.upload-button {
  & > .ant-upload {
    height: 70px;
    width: 70px;
    padding: 6px;
    text-align: center;
    vertical-align: top;
    background-color: #f5f5f5;
    border: 1px dashed #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    transition: border-color 0.3s;

    &__checked {
      border: 2px solid #0091ff;
    }

    & .upload-button__inner {
      & .add-icon {
        font-size: 22px;
        color: #878787;
      }

      & .ant-space {
        line-height: 10px;
        color: #878787;
      }
    }
  }
}
