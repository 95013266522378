.description-field {
  & .ant-input {
    font-family: "ProximaNova-Semibold";
    font-size: 14px;
  }

  &.ant-input-textarea-show-count::after {
    font-size: 10px;
    margin-top: 5px;
  }
}
