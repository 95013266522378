@font-face {
  font-family: "BlissPro-Regular";
  src: local("BlissPro-Regular"), url(./BlissPro-Regular.otf) format("opentype");
}

@font-face {
  font-family: "BlissPro-Light";
  src: local("BlissPro-Light"), url(./BlissPro-Light.otf) format("opentype");
}

@font-face {
  font-family: "BlissPro-Bold";
  src: local("BlissPro-Bold"), url(./BlissPro-Bold.otf) format("opentype");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: local("ProximaNova-Regular"), url(./ProximaNova-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: local("ProximaNova-Semibold"), url(./ProximaNova-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: local("ProximaNova-Bold"), url(./ProximaNova-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "ProximaNova-Black";
  src: local("ProximaNova-Black"), url(./ProximaNova-Black.ttf) format("truetype");
}
