.ant-modal-root {
  .recovery-tasks-notification {
    &.critical {
      .ant-modal-header {
        background-color: #fff2f0;
      }
    }
    &.high,
    &.medium {
      .ant-modal-header {
        background-color: #fffbe6;
      }
    }
    &.low {
      .ant-modal-header {
        background-color: #f6ffed;
      }
    }
  }
}
