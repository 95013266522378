.task-modal {
  .picture-card-wrapper {
    position: relative;
    height: 70px;
    min-height: 70px;

    & .media-selection-checkbox {
      position: absolute;
      top: 4px;
      left: 14px;
      z-index: 2;

      & .ant-checkbox-checked::after {
        display: none;
      }

      & .ant-checkbox-inner {
        border-radius: 10px;
      }
    }
  }

  .ic-close {
    position: absolute;
    top: -12px;
    right: -8px;
    padding: 0;
    z-index: 2;
  }

  .media-upload {
    height: 100%;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 12px;
    cursor: pointer;
    transition: border-color 0.3s;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .ant-col {
      padding: 0 !important;
    }
    &-image {
      display: flex;
      height: 70px;
      width: 100%;
    }
    &__checked {
      border: 2px solid #0091ff;
    }
  }

  .box-icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-name {
    display: flex;
    flex-direction: column;
    line-height: normal;
  }
}
