.journal-table {
  font-family: "ProximaNova-Semibold";

  & .ant-table-thead > tr > th {
    &.journal-table__column {
      background: none;
      padding: 6px 12px;

      &--colored {
        color: #0091ff;
        background-color: #0091ff1a;
      }
    }

    &::before {
      display: none;
    }
  }
}
