.assistance-car-modal {
  & .ant-btn {
    text-transform: uppercase;

    &.ant-btn-default {
      color: #0077e2;

      &:disabled {
        background: none;
        color: #ccc;
      }
    }

    &.middle {
      font-family: "ProximaNova-Semibold";
      height: 28px;
      font-size: 12px;
    }

    &.large {
      font-family: "ProximaNova-Semibold";
      height: 48px;
      font-size: 18px;
    }
  }
}
