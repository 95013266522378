@import "uikit/themes/default/variables.scss";

.recovery-task-modal {
  .blue {
    color: #0091ff;
  }
  .link {
    cursor: pointer;
  }
}
