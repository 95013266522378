.menu-main {
  .MuiAccordion-root {
    box-shadow: none;
    margin: 0px;
    &:before {
      height: 0px;
    }
    .MuiAccordionSummary-root {
      border-bottom: none;
    }
    .MuiAccordionDetails-root {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
