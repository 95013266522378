.decorElement {
  z-index: 8;
  position: absolute;
  max-width: 380px;
  height: auto;
  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.decor-1,
.decor-4 {
  margin-top: 50px;
  top: 0;
}

.decor-3,
.decor-6 {
  margin-bottom: 50px;
  bottom: 0;
}

.decor-1,
.decor-2,
.decor-3 {
  margin-left: 50px;
  left: 0;
}

.decor-4,
.decor-5,
.decor-6 {
  margin-right: 50px;
  right: 0;
  justify-content: flex-end;
}
